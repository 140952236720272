import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import FaqParagraph from './faq_paragraph';

const isValidContent = ({ name, acceptedAnswer }) => name && acceptedAnswer && acceptedAnswer.text;

const generateFaqParagraphs = (content) => {
  if (Array.isArray(content)) {
    return content
      .filter(isValidContent)
      .map(({ name, acceptedAnswer }) => (
        <FaqParagraph key={name} title={name} content={acceptedAnswer.text} />
      ));
  } if (isValidContent(content)) {
    return (
      <FaqParagraph
        key={content.name}
        title={content.name}
        content={content.acceptedAnswer.text}
      />
    );
  }
  return null;
};

const FaqSection = forwardRef(({ title, content }, ref) => {
  const faqContent = generateFaqParagraphs(content);

  if (!faqContent || !faqContent.length) return null;

  return (
    <div className="site-faq-section-wrapper">
      <section className="site-faq-section" ref={ref}>
        <header className="site-faq-section__header">
          <div className="site-faq-section__title">
            <h2 className="bvs-h2">{title}</h2>
          </div>
        </header>
        <div>
          {faqContent}
        </div>
      </section>
    </div>
  );
});

FaqSection.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.object,
  ]).isRequired,
};

export default FaqSection;
