import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { isDesktop } from 'bv';
import { isCasinoPath } from 'Seo/helpers/seo_enabled';
import ExpandableParagraph from './expandable_paragraph';

const collapsedTextLength = 200;

const CopySection = forwardRef(({
  content,
  title,
}, ref) => (
  <section className="site-copy-section" ref={ref}>
    <header className="site-copy-section__header">
      <div className="site-copy-section__title">
        <div dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
      </div>
    </header>

    {(isDesktop() || isCasinoPath() || content.length <= collapsedTextLength) ? (
      <div
        className="site-copy-section__content"
        dangerouslySetInnerHTML={{
          __html: sanitize(content,
            { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }),
        }}
      />
    ) : (
      <ExpandableParagraph text={content} />
    )}
  </section>
));

CopySection.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default CopySection;
